import './App.css';
import { useState } from 'react';
import axios from 'axios';

function App() {

  const [poss_y, set_poss_y] = useState({
    lam1: 0,
    lam2: 0,
    lam3: 0,
  });

  const [formData, setFormData] = useState({
    email: '',
    nom: '',
    prenom: '',
    code_postal: '',
  });

  const [uri_gain, set_uri_gain] = useState('');

  const [hidde, setHidde] = useState(false);

  const send = async (e) => {

    e.preventDefault();

    const url = 'https://api-gain.web-treeosk.online/play';

    await axios.post(url, {
      input: {
        game_id: '2ed759a0-d492-41e8-8676-efc80dfb45eb',
        lang: 'fr',
        player_data: formData
      }
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then((res) => {
        const uri = res.data.data.uri;
        setTimeout(() => { set_uri_gain(uri); }, 11000);
        let rotate = 0;
        setHidde(true);
        switch (res.data.data.id) {
          case '636a9d00-f8f8-478d-add6-5c23a9361eae':
            set_poss_y({
              lam1: randomInt(3500, 7500),
              lam2: randomInt(3500, 7500),
              lam3: randomInt(3500, 7500),
            });
            break;
          case '33610269-372b-466e-84f3-032de4d9fbf2':
            rotate = 3880;
            break;
          case 'ff9792ec-ecfa-4720-9312-9dffb67ff0b5':
            rotate = 3480;
            break;
          case '9a29a2cf-0321-4646-ad2f-6f5a5326cba9':
            rotate = 5200;
            break;
          case '832461c6-5daf-4d59-88ca-c560d5304e0b':
            rotate = 4000;
            break;
          case '7590785b-0e0e-4505-b826-0ef998ca0412':
            rotate = 4980;
            break;
          case '5149f362-2778-4fe6-b901-c3a1653b1938':
            rotate = 3700;
            break;
          case '09175c08-6b6a-4cd4-80a3-41a65e3e48b2':
            rotate = 5820;
            break;
          case 'c825a842-c3ea-4477-8be1-b6da1c4c4c72':
            rotate = 4560;
            break;
          case 'adabdcb4-11e1-4644-a576-c513c01df7fc':
            rotate = 3340;
            break;
          case '4c2f73ef-2bf0-433f-a20c-f81b9e4fa8f8':
            rotate = 4760;
            break;
          case '2a408fc3-1ee0-472c-816d-2c18b22320fd':
            rotate = 6200;
            break;
          case '5cbbadc5-d14c-4557-a4e1-e94823a22306':
            rotate = 6400;
            break;
          case 'f9225c1a-0ea3-4947-841a-6afdc58cd548':
            rotate = 6020;
            break;
          case '270a348a-56a6-487a-a4c3-ccaeb7c86a28':
            rotate = 5620;
            break;
          case '1eb80dc1-f9e9-49d3-9883-be654f4d30e3':
            rotate = 7610;
            break;
          case '1f976e87-4705-48fa-b310-1d6136c6a22c':
            rotate = 6620;
            break;
          case 'ce8db1ab-a79a-429a-a126-a821d9e0135f':
            rotate = 5410;
            break;
          case 'ce7901b6-7709-4532-be0b-3bac0c5fa47f':
            rotate = 4360;
            break;
          case '5c17d506-4a34-4bf4-b93a-f7595d25823c':
            rotate = randomInt(3500, 7500);
            break;
          default:
            return;
        }
        if (res.data.data.id !== '636a9d00-f8f8-478d-add6-5c23a9361eae') {
          set_poss_y({
            lam1: rotate,
            lam2: rotate,
            lam3: rotate,
          })
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la requête POST :', error.response ? error.response.data : error.message);
      });
  }

  return (
    <div className="App">
      <div id="box">
        <div className="lamelles">
          <div className="lamelle" id="lam1" style={{ backgroundPositionY: poss_y.lam1 + 'px' }}>

          </div>
          <div className="lamelle" id="lam2" style={{ backgroundPositionY: poss_y.lam2 + 'px' }}>

          </div>
          <div className="lamelle" id="lam3" style={{ backgroundPositionY: poss_y.lam3 + 'px' }}>

          </div>
        </div>
      </div>

      {
        !hidde &&
        <div className='form'>
          <form onSubmit={send}>
            <input required value={formData.nom} onChange={(e) => { setFormData({ ...formData, nom: e.target.value }) }} className='input' placeholder='Nom' />
            <input required value={formData.prenom} onChange={(e) => { setFormData({ ...formData, prenom: e.target.value }) }} className='input' placeholder='Prénom' />
            <input type='email' value={formData.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} className='input' placeholder='Email' />
            <input type='number' required value={formData.code_postal} onChange={(e) => { setFormData({ ...formData, code_postal: e.target.value }) }} className='input' placeholder='Code postal' />
            <br />
            <button id='submit' type='submit'>
              Play !
            </button>
          </form>
        </div>
      }
      {
        !!uri_gain.length &&
        <img src={uri_gain} style={{ width: '400px', height: 'auto', marginTop: '-1000px' }} />
      }
    </div>
  );
}

export default App;

function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}